import React, { Component } from 'react';

import logoUrl from '../logo.png';

class Logo extends Component {
  render() {
    return (
      <div className="App-header">
        <div className="container">
          <header className="p-2 p-md-4 p-lg-2 ps-xl-5">
            <div align="center">
              <img
                src={logoUrl}
                alt="Check N Title"
                className="img-responsive logo-img"
              />
            </div>
          </header>
        </div>
      </div>
    );
  }
}
export default Logo;
