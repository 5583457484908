import React from 'react';

import checkmark from '../check.png';
import callcenterUrl from '../cc2.png';
import twentyYears from '../20-years-logo.png';
import bgUrl from '../check-n-title-home.jpg';

function Banner({ store, customerPhone }) {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 0',
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="mb-0">
            <div className="pt-2 pe-2 pb-0 ps-2">
              <div className="pt-2 pt-lg-3 pt-xl-3 ps-2 ps-lg-5 ps-xl-5 pe-2 pe-lg-5 pe-xl-5">
                <h2 className="text-center">
                  Welcome to Check N Title!
                </h2>
                <div className="text-center pt-3 pb-3">
                  <strong>
                    Quick Approval - Most Credit Types Accepted -
                    Title-Secured Loan and Personal Loan Options
                  </strong>
                  <br />
                  <br /> Get up to $50,000 - Flexible Payment Options
                  <br />
                  {customerPhone ? (
                    <div>
                      An experienced representative will be reaching
                      out to the number you provided:&nbsp;
                      <span className="customer-phone">
                        {customerPhone}
                      </span>
                      <br />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {store ? (
                    <div>
                      Can't wait? Call us at &nbsp;
                      <a href={'tel: ' + store.phone}>
                        {store.phone}
                      </a>
                      &nbsp; to expedite your service.
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 sm-6">
                    <div className="process-title">
                      <strong>The process is easy!</strong>
                    </div>
                    <div className="pt-1 pb-2 row">
                      <div className="col-1">
                        <img
                          src={checkmark}
                          alt="check"
                          width="30"
                          height="30"
                        />
                      </div>
                      <div className="col-11 pt-1">
                        Speak with a customer service representative.
                      </div>
                    </div>
                    <div className="pt-1 pb-2 row">
                      <div className="col-1">
                        <img
                          src={checkmark}
                          alt="check"
                          width="30"
                          height="30"
                        />
                      </div>
                      <div className="col-11 pt-1">
                        Visit one of our stores to complete the
                        process.
                      </div>
                    </div>
                    <div className="pt-1 pb-2 row">
                      <div className="col-1">
                        <img
                          src={checkmark}
                          alt="check"
                          width="30"
                          height="30"
                        />
                      </div>
                      <div className="col-11 pt-1">
                        Get approved for your cash.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="img-girl" align="right">
                      <div className="pt-0"></div>
                      <img
                        src={callcenterUrl}
                        alt="callcenter"
                        className="img-responsive text-center"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2">
                    <div className="img-20years">
                      <img
                        src={twentyYears}
                        alt="20 years in Texas"
                        className="img-responsive text-center"
                        width={120}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us">
        <div className="container">
          <div className="p-2 p-md-4 p-lg-2 ps-xl-5 pe-xl-5">
            <div className="text-center pt-2">
              <strong className="about-us-header">About Us:</strong>
            </div>
            <div className="p-2 text-start">
              Check N Title Loans has been servicing the Greater
              Dallas / Fort Worth area with installment and title
              loans for over 20 years! Every day, Check N Title helps
              people get up to $50,000 through a title-secured loan
              (vehicle, motorcycle, ATV, RV, Trailer, boat) or up to
              $1,500 through an unsecured installment loan. Customers
              considering alternative loans can visit one of our store
              locations or apply online all across the state of Texas.
              We pride ourselves on our experience and excellent
              customer service. With our willingness to meet or beat
              any rate, we hope you choose us to help with your
              financial challenges when you need cash fast.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
