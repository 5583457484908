import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

class FooterMobile extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="footer-details">
          <div className="container">
            <div className="footer2">
              <div>
                &copy; All Rights Reserved, Check N Title Loans
              </div>
              <hr />
              <div className="footer-menu text-center">
                <a
                  href="https://www.checkntitle.com/AboutUs.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  About Us
                </a>
                <a
                  href="https://www.checkntitle.com/Contact.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Contact Us
                </a>
                <a
                  href="https://www.checkntitle.com/Faq.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  FAQ
                </a>
                <a
                  href="https://www.checkntitle.com/Blog.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Blog
                </a>
                <a
                  href="https://www.checkntitle.com/#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Press
                </a>
                <a
                  href="https://www.checkntitle.com/Contact.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Support
                </a>

                <a
                  href="https://www.checkntitle.com/Legal.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Legal
                </a>
                <br />
                <a
                  href="https://www.checkntitle.com/SeHablaEspanol.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Se Habla Español
                </a>
              </div>
              <div className="text-center mt-5 mb-5">
                <a
                  href="https://www.facebook.com/CNTLoan/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link socialmedia-link pe-5"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://www.instagram.com/checkntitle/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link socialmedia-link"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </div>
              <p>
                - Rules and restrictions may apply. This licensed
                business is regulated by the Office of Consumer Credit
                Commissioner. 3821 S Buckner, Blvd, Dallas TX 75227
              </p>
              <p>
                - Customer Notice: Advance of money and extensions of
                credit should be used for short-term financial needs
                only, not as a long-term financial solution. Customers
                with credit difficulties should seek credit
                counseling. The Website does not constitute an offer
                or solicitation to offer a loan.
              </p>
              <p>
                - Not all loan applications or extension requests are
                approved. Due to state, verification and approval
                requirements, not all applicants for online loans will
                be eligible for approval by Check N Title Loans or
                third-party lenders.
              </p>
              <p>
                - A "pre-approval" does not mean that you will receive
                a loan. For final approval, you must meet certain
                criteria and verifications. - Some customers applying
                for Check N Title Loans or third-party lender loans
                may be required to submit additional documentation due
                to state law and qualification criteria.
              </p>
              <p>
                - We do not perform traditional credit checks as part
                of our credit transaction approval process, but do
                verify applicant information via national databases
                including, but not limited to, DataX and Clarity. If
                you have defaulted on past obligations, you may be
                declined for a extension of credit or cash advance
                from this lender. Maximum funded amount for Check N
                Title Loans or third-party lender loans depends on
                qualification criteria and state law. See Rates &
                Terms for details.
              </p>
              <p>
                - Check N Title Loans complies with applicable
                collection laws. Check N Title Loans will contact you
                using one or more authorized communication methods.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterMobile;
